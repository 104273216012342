/* MAIN BODY */
.fontPrimary {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  opacity: 0.88;
  color: #faf9f6;
  font-variant: small-caps;
}

.fontPrimary.title {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  opacity: 0.88;
  color: #06191D;
}

.fontSecondary {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  opacity: 0.88;
  color: #faf9f6;
}

@media (min-width: 768px) {
  .fontNavigation {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    opacity: 0.88;
  }
}

body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  height: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.heading {
  text-shadow: -5px 0 #061e45;
}